@import "../../../styles/colors.scss";


.AlertBar {
  z-index: 1200;

  font-size: 1em;
  overflow: hidden;
  background-color: $foreground-secondary;
  color: $background-primary;

  .Content {
    padding: 1em;
    display: grid;
    grid-template: none/ 3em 1fr 3em;
  }

  .Warning {
    background-color: $foreground-secondary;
  }

  .Success {
    background-color: $foreground-accent;
  }

  .Close {
    text-align: right;

    &:hover {
      cursor: pointer;
    }
  }

  .Loading {
  svg {
    fill: $background-primary;
  }
}

  margin-bottom: 0.5em;

  input[type="button"] {
    background-color: $background-primary;
    color: $foreground-primary;
    background: $button-gradient-dark;
    border: 1px solid $foreground-secondary;
    // border-radius: 8px;
    font-weight: bold;
    font-size: 1.2em;
    padding: 1px;
    text-decoration: none;
    text-align: center;
    line-height: 2.4em;
    width: 10em;
    cursor: pointer;

    display: block;
    height: 2.4em;
    border: 1px solid $foreground-secondary;
    border-radius: 8px;

    &:active {
      background: $button-gradient-dark-active;
      border: 1px solid $foreground-accent;
    }

    &:hover {
      color: $foreground-accent;
    }

    &:disabled {
      opacity: 0.25;
    }
  }
}
