@import "../../styles/colors.scss";

.PageWithMenu {
  display: grid;
  grid-template-columns: 4.5rem 1fr;
  grid-template-rows: 1fr;
  background-color: $background-secondary;

  & > .PageContentWrapper {
    width: 100%;
    height: 100vh;
    background-color: $background-primary;

    & > .PageContent {
      padding: 1em;

      max-height: calc(100vh - 2em);

      overflow-y: auto;
    }
  }
}


.PageWithMenu.mobile {
  grid-template-columns: 1fr;
  grid-template-rows: 4.5em 1fr;
}

.PageWithMenu.browser {
  grid-template-columns: 4.5em 18em 1fr;
  grid-template-rows: 1fr;
}
