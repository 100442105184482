@import "../../styles/colors.scss";


.Page {
  display: grid;
  grid-template-columns: 4.5rem 1fr;
  grid-template-rows: 1fr;
  background-color: $background-secondary;

  & > .PageContentWrapper {
       width: 100%;
       height: 100vh;


      & > .PageContent {
      padding: 1em;

        max-height: calc(100vh - 2em);

        overflow-y: auto;


      }
  }

}

.Page.mobile {

}


.Page.mobile {
  grid-template-columns: 1fr;
  grid-template-rows: 4.5em 1fr;
}

.Page.browser {
  grid-template-columns: 4.5em  1fr;
  grid-template-rows: 1fr;
}
