@import "../../styles/colors.scss";

.Login {
  color: $foreground-primary;
  z-index: 3;
  position: relative;

  a {
    text-decoration: underline;
  }

  h1 {
    text-align: center;
    font-size: 8em;
    color: $background-secondary;
    width: 100%;
    margin-bottom: 0em;
    // border-bottom: 1px solid $foreground-secondary;
  }
  .Logo {
    padding: 0.5em 0;
    fill: $background-secondary;
    text-align: center;
    font-size: 2em;
    color: $background-secondary;
    width: 100%;
    height: 4em;
    margin-bottom: 0em;
  }
  a {
    margin-top: 1em;
    width: 100%;
    text-align: center;
    color: $foreground-secondary;

    &:hover {
      color: $foreground-accent;
    }
  }

  .LoginHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
    background-color: $background-secondary;

    span {
      text-align: center;
      padding: 0.5em;
    }

    .active {
      border-bottom: 4px solid $foreground-secondary;
    }
  }

  .FormContainer {
    padding-top: 0.5em;
    background-color: $background-secondary;
    // border-bottom: 1px solid $foreground-secondary;
    border-radius: 0 0 8px 8px;
    .LoginForm {
      padding: 1em;
    }
  }

  .RegisterRadioOption {
    // font-size: 1.5em;
    font-weight: bold;

    justify-content: flex-start;

    & > * {
      margin-right: 1em;
    }
  }
}

.Detail {
  padding-left: 1em;
  margin-left: 4em;
  margin-bottom: 1em;
  border-left: 4px solid $foreground-secondary-translucent;
}


.AuthenticationLayout {
  display: grid;
  grid-template-columns: 1fr 30rem 1fr;
  grid-template-rows: 1fr;
}


.Background {
  z-index: -1;
}
