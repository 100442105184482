@import "../../../styles/colors.scss";

.Toasts {
  position: fixed;
  right: 2em;
  bottom: 1em;
  z-index: 1000;
  display: flex;
  flex-direction: column-reverse;

  .Toast {
    width: 15em;
    display: flex;
    flex-direction: row;
    color: $background-secondary;
    background-color: $foreground-secondary;
    padding: 8px;
    margin-bottom: 0.5em;

    .Message {
      display: flex;
      flex-direction: row;
      flex: 2 0 0;
    }

    .Close {
      text-align: right;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
