@import "../../../../styles/colors.scss";

.CollapsibleLayer {
    & > .CollapsibleLayerHeader {
        display: grid;
        grid-template-columns: 2em  1fr 2fr ;

    }

    & > .Expander {
        text-decoration: underline;
        color: $foreground-accent;
        cursor: pointer;

        text-align: center;
        div.open {
            border-bottom: 4px solid $foreground-primary;
        }
        div.closed {
            border-bottom: 4px solid $foreground-primary;

        }
    }

    & > .LayerContent {
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
-moz-animation: fadein 1s; /* Firefox < 16 */
-ms-animation: fadein 1s; /* Internet Explorer */
-o-animation: fadein 1s; /* Opera < 12.1 */
animation: fadein 1s;

    }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
