@import "../../styles/colors.scss";


.ReferralsList {
  div {
    display: grid;
    grid-template-columns:  1fr 1.5fr repeat(5, 1fr);
    grid-gap: 1em;
    line-height: 2em;

    &:first-of-type {
      font-weight: bold;
      color: $background-primary;
      background-color: $foreground-secondary !important;
    }

    &:nth-child(odd) {
      background-color: $background-secondary;
    }
  }
}


.SlideWrapper {
  input[type=text], input[type=number], input[type=password],textarea, select {
    width: 20em;
  }

}

.TotalAcres {
  display: grid;
  line-height: 2em;
  width: 20em;
  grid-template-columns: repeat(2, 1fr);
  &:nth-child(2) {
    color: $foreground-accent;
  }
}

.AccountTool {
  text-decoration: underline;

  &.active {
    font-weight: bold;
    color: $foreground-accent;
  }
}

.AccountWrapper{
  width: 100%;
  height: 100vh;

  .RightPanelContent {
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;
    overflow-y: auto;

    .DeleteWarning {
      color: $orange-red-yield;
      font-weight: bold;
      padding-bottom: 1em;
    }

    .DeleteButton {
      background: $button-gradient-red;
      border: $red-yield;

      &:active {
        background: $button-gradient-red-active;
        border: 1px solid $orange-red-yield;
      }
  
    }

   
  }
}

.DataManagement {

  line-height: 1em;

  .ActionIcon {
    color: $foreground-accent;

    &:hover {
    
      filter: drop-shadow(0px 0px 5px $foreground-secondary);
    }

  }

  .Field {
    h3 {
      color: $foreground-secondary;
    }
  }

  .StatusIcons {
    display: grid;
    grid-template-columns: 15em 2em 2em 2em 2em;
  }

  .Link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  ul {
    list-style: none;
    margin-left: 3em;

    li {
      display: grid;
      grid-template-columns: 10em 2em 2em;

      &:first-of-type {
        font-weight: bold;
        color: $background-primary;
        background-color: $foreground-secondary !important;
      }

      &:nth-child(odd) {
        background-color: $background-secondary;
      }
    }
  }

  table {
    border-spacing: 0;
    width: 100%;

    thead {

      tr {
        th {
          position: sticky;
top: 0;
z-index: 2;
background-color: $background-primary;

          &:nth-child(1) {
            border-left: 0;
          }
          border-left: 1px solid $foreground-secondary-translucent;
          border-bottom: 1px solid $foreground-secondary-translucent;

      }
      }
    }

    tbody {
      border-bottom: 1px solid $foreground-secondary-translucent;

      tr {
        &:nth-child(odd) {
          background-color: $background-secondary;
        }
        &:nth-child(even) {
          background-color: $background-primary;
        }

        td {

          &:nth-child(1) {
            border-left: 0;
          }
          border-left: 1px solid $foreground-secondary-translucent;
        }

        &:hover {
          background-color: $everglade;
        }
      }
    }
  }

  .table_wrapper {
    overflow-y: scroll;
    min-height: 10em;
    max-height: 35em;

  }
}
