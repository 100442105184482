@import "../../styles/colors.scss";

.ToolboxWrapper {
  background-color: $background-secondary;
  color: $foreground-primary;
  width: 100%;
  height: 100vh;
  // border-right: 5px solid $foreground-secondary !important;

  .Toolbox {
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;
    overflow-y: auto;

    header {
      text-transform: capitalize;
    }

    .Tools {
      & > div:not(:last-child) {
        border-bottom: solid 1px $foreground-secondary-translucent;
        padding-bottom: 1em;
        margin-bottom: 1em;
      }

      ul {
        list-style: none;
      }
    }
  }
}

.ToolboxLayout {
  display: grid;
}




