$background-secondary: hsl(25, 15%, 21%);
$foreground-primary: hsl(51, 58%, 82%);
$foreground-secondary: hsl(47, 87%, 70%);
$foreground-secondary-translucent: hsla(47, 87%, 70%, 0.5);
$background-primary: hsl(25, 10%, 14%);
$foreground-accent: hsl(78, 68%, 68%);
$forest-green: #256d1b;
$everglade: #244f26;

$orange-red-yield: #FF5733;
$red-yield: #d12602;

$button-gradient: linear-gradient($foreground-primary, $foreground-accent);
$button-gradient-active: linear-gradient($foreground-accent, $foreground-primary);

$button-gradient-dark: linear-gradient($background-secondary, $background-primary);
$button-gradient-dark-active: linear-gradient($background-primary, $background-secondary);

$button-gradient-red: linear-gradient($orange-red-yield, $red-yield);
$button-gradient-red-active: linear-gradient($red-yield, $orange-red-yield);
