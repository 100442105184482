@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";


.Home {
  // font-size: 20px;

  width: inherit;

  .ContentNav {
    display: flex;
    flex-direction: column;
    div:nth-of-type(2n) {
      background-color: $foreground-accent;
    }
  }

  .HeaderHome {
    background-image: url(../../assets/img/sunset-241806.jpg);
    background-size: cover;
    width: 100%;
    height: calc(100vh - 5em);

    .Logo {
      padding: 0.5em 0;
      fill: $background-secondary;
      text-align: right;
      color: $background-secondary;
      height: 8em;
      margin-bottom: 0em;
    }

    .HeaderText {
      @media screen and (min-width: $break-small) {
        position: absolute;
        top: 1em;
        right: 1em;
      }

      margin: 0 auto;

      display: flex;
      flex-direction: column;
      font-size: 20px;
      color: $background-secondary;
      font-weight: bolder;
      width: 17em;

      span {
        font-size: 24px;

        @media screen and (min-width: $break-small) {
          padding-right: 2em;
          text-align: right;
        }
      }
    }


  }

  .SectionHeading {
    text-shadow: 5px 2px 20px $background-primary;
    border-bottom: 1px solid $foreground-secondary-translucent;
  }

  .LinkButton {
    background-color: $background-primary;
    color: $foreground-primary;
    background: $button-gradient-dark;
    border: 1px solid $foreground-secondary;
    // border-radius: 8px;
    font-weight: bold;
    font-size: 1.2em;
    padding: 1px;
    text-decoration: none;
    text-align: center;
    line-height: 2.4em;
    width: 10em;
    cursor: pointer;

    display: block;
    height: 2.4em;
    border: 1px solid $foreground-secondary;
    border-radius: 8px;

    &:active {
      background: $button-gradient-dark-active;
      border: 1px solid $foreground-accent;
    }

    &:hover {
      color: $foreground-accent;
    }

    &:disabled {
      opacity: 0.25;
    }
  }

  .Navigation {
    width: 100%;
    height: 5.7em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: $foreground-secondary;
    background-color: $background-primary;

    a {
      text-align: center;
      height: 4em;
      line-height: 4em;
      cursor: pointer;
      text-decoration: none;
      font-weight: bold;
      color: $foreground-secondary;
      background-color: $background-primary;

      border-bottom: 10px solid $background-primary;

      flex-basis: 0;
      flex-grow: 1;

      span {
        display: inline-block;

        line-height: 1em;
        margin: auto;
        vertical-align: middle;
      }

      &:hover,
      &.active {
        border-bottom: 10px solid $foreground-accent;
        color: $foreground-accent;
        // height: 100%;
      }
    }
  }

  .PanelWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4em;

    @media screen and (max-width: $break-small) {
      grid-template-columns: 1fr;

      .PanelContent {
        width: 90vw;
      }
    }
  }

  .Section {
    padding: 5em;
    background-color: $background-secondary;

    @media screen and (max-width: $break-small) {
      padding: 2em;
      padding-top: 5em;
    }

    @media screen and (min-width: $break-large) {
      min-height: 70em;
      height: 100vh;
    }

    &.Top {
      padding-top: 0;
    }

    .Team {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2em;

      @media screen and (max-width: $break-small) {
        grid-gap: 1em;
        width: 90vw;
      }
    }

    .Logo {
      padding: 0.5em 0;
      fill: $foreground-secondary;
      text-align: right;
      color: $foreground-secondary-translucent;
      margin-bottom: 0em;
      width: 80%;
    }
  }

  .InlineLogo {
    display: inline-block;
    padding: 0.5em 0;
    fill: $foreground-secondary;
    color: $foreground-secondary;
    height: 3em;
    margin-bottom: 0em;
  }

  .ProfilePhoto {
    border-radius: 50%;
  }

  .CarouselImage {
    max-height: calc(100vh * 0.8);
    width: 100%;

    img {
      //height: 100%;

      // width: auto;
    }
  }

  .Footer {
    text-align: center;

    background-color: $background-secondary;

    div {
      margin: 0 2em;

      &:first-of-type {
        border-top: 1px solid $foreground-secondary-translucent;
        padding-top: 1em;
      }
      &:last-of-type {
        padding-bottom: 1em;
      }
    }
  }
}

.Testimonials {
  display:grid;
  grid-template-columns: 1fr 3fr;

  .TestimonialSelector {

    .option {
      margin:0;
      padding: 5px;
      border-bottom: 1px solid $foreground-secondary-translucent;
      border-top: 1px solid $foreground-secondary-translucent;
      border-right: 1px solid $foreground-accent;
      color: $foreground-secondary-translucent;
      border-left: 1px solid $foreground-secondary-translucent;
    
    }

    .active {
      color: $foreground-accent;
      background-color: $background-primary;

      border-bottom: 1px solid $foreground-accent;
      border-top: 1px solid $foreground-accent;
      border-right: 1px solid $background-primary;
      border-left: 1px solid $foreground-accent;


    }
  }

  .TestimonialVideos {
    border-right: 1px solid $foreground-accent;
    border-top: 1px solid $foreground-accent;
    border-bottom: 1px solid $foreground-accent;

    padding: 1em;
    background-color: $background-primary;
    
  }
}

.VideoIconContainer {
  border: 2px solid $foreground-secondary-translucent;
  color: $foreground-secondary-translucent;

  &:hover {
    border: 2px solid $foreground-accent;

    .VideoIconOverlay {
    color: $foreground-accent;
    }

  }
}

ol {
  li {
    font-size: 18px;
    margin-top: 1em;
  }
}
