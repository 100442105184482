@import "../../styles/colors.scss";

.TabBar {
  height: calc(100vh - 8.5rem) ;

  display: grid;
  grid-template-rows: 3em 1fr;
  grid-template-columns: 1fr;
  background-color: $background-secondary;
      
  
}

.Tabs {
       width:100%;
      display: grid;
      text-align: center;

      & > div {
    padding: 1em;
    box-shadow: 1px 1px 1px $background-primary;



      }

      & > .ActiveTab {
  border-bottom: 3px solid $foreground-accent;
  

}}



.TabBarContentWrapper {
  width: 100%;
  height: calc(100vh - 4.5rem);

  
}

.TabBarContent {
  padding: 1em;

  height: 600px;

  overflow-y: auto;
}
