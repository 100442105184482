@import "../../../styles/colors.scss";

.Loading {
  // width: 100%;
  text-align: center;
  font-size: 1em;
  svg {
    height: 1em;
    fill: $foreground-primary;
  }
}
