@import "../../styles/colors.scss";

.FieldCardSelect {
    display:grid;
    grid-template-columns: .5em 1fr;
    //border: 1px solid $foreground-secondary-translucent;
    min-height: 4em;
    min-width:100%;
    background-color: $background-primary;
    box-shadow: 1px 1px 4px $foreground-secondary-translucent;
    border-radius: 5px;
    margin:  .5em 0;
 

    .FarmColorBlock {
        background-color: green;
        border-radius: 5px 0 0 5px;

    }

    .FieldCardContent {
        display: grid;
        grid-template-columns: 3fr 4fr;
        padding: 2px ;


        & > div {
            padding: 2px;
        }

        & > div:first-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;
            word-break: break-all;


           div:first-child {
            font-weight: bold;
            }
            div:last-child {
                font-size: .8em;
            }
        } 

        & > div:nth-child(2) {
            
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            flex-direction: row;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: space-evenly;

            .year {
                display: flex;
                flex-direction: column;
                font-size: .8em;
            }
        }

    }

}
