@import "../../styles/colors.scss";


.Slider {
  display: grid;
  grid-template-columns: 3em 3em 1fr;
  padding-bottom: 2em;
  text-align: center;
  align-items: center;
  span:nth-of-type(1) {
    text-align: left;
    font-weight: bolder;
  }
}


.SlideModal__header {
  background-color: $background-secondary !important;

  box-shadow: 0px 0px 0px 0px $foreground-secondary-translucent !important;
  border-bottom: 1px solid $foreground-secondary-translucent;

  .SlideModal__title {
    color: $foreground-secondary;
  }
}

.SlideModal__footer {
  box-shadow: 0 0 0 0 $foreground-secondary-translucent !important;
  border-top: 1px solid $foreground-secondary-translucent;

  input[type="button"] {
    width: 100%;
  }
}

.SlideModal {
  background-color: $background-secondary !important;

  form {
    padding: 1em;
  }
}
