@import "../../styles/colors.scss";

.FileUpload {
  .FileDropzone {
    border: 4px dashed $foreground-secondary;
    width: calc(100% - 8px);
    height: 20em;
    // input: {
    //   width: 90%;
    // }
    p {
      padding: 1em;
    }
  }
}

.DashboardPageContent {
  display: grid;
  grid-gap: 1em;

  & > div {
    background-color: $background-primary;
    border: 1px solid $foreground-secondary-translucent;
    border-radius: 5px;
    padding: 1em;

  }

}


// .Notices {
//   li {
//     @extend .Notice;
//   }
// }

// .HealthStatus {
//   ul {
//     li {
//       @extend .Notice;
//       background-color: $neutral-color;
//       color: $background-primary;
//       border-radius: 8px;
//       font-weight: bold;
//     }
//   }
// }


.DashboardPageContent.mobile {
grid-template-columns: 1fr ;

}

.DashboardPageContent.browser {
  grid-template-columns: 1fr 1fr;


}