@import "../../styles/colors.scss";

.HeatmapLegend {
  display: grid;
  margin-bottom: 1em;

  // &:nth-child(odd) {
  //   background-color: $background-secondary;
  // }
  // &:nth-child(even) {
  //   background-color: $background-primary;
  // }

  .HeatmapLegendTitle {
    background-color: $foreground-secondary;
    height: 2em;
    color: $background-secondary;
    font-weight: bolder;

    &:hover {
      background-color: $foreground-secondary !important;
    }
  }

  div {
    align-content: center;
    border: 1px solid transparent;
    height: 1.5em;

    &:nth-of-type(2n) {
      background-color: $background-primary;
    }

    &:hover {
      border: 1px solid $foreground-secondary-translucent;
      // background-color: $forest-green;
    }

    &.active {
      border: 1px solid $foreground-secondary;

      .Status {
        border: 1px solid $foreground-secondary;
      }
    }
  }

  .Modified {
    color: $foreground-accent;
  }
}
