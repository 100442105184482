@media print {
  @page {
    size: landscape;
  }
  // body {
  //   writing-mode: tb-rl;
  // }

  .Toolbar {
    display: none;
  }

  .ToolboxLayout {
    display: grid;
    grid-template-columns: 1fr;
  }

  .Farms {
    display: none;
  }

  .SliderWrapper {
    display: none;
  }

  input[type="button"] {
    display: none;
  }

  .Map {
    .leaflet-container {
      width: 50em;
      height: 100vh;
    }

    .leaflet-control {
      display: none;
    }
    .leaflet-control-attribution {
      display: block !important;
    }
  }

  .Toolbox {
    color: black;
    background-color: white;

    .Tools {
      & > div:not(:last-child) {
        border-bottom: solid 1px black !important;
      }
    }

    h1 {
      color: black;
    }
    select {
      color: black;
      background-color: white;
      border: 1px solid black;
    }

    .HeatmapLegend {
      div {
        &:first-of-type {
          border-bottom: 1px solid black;
          background-color: white;
          color: black;
        }
      }
    }
  }

  .StatusIndicator {
    -webkit-print-color-adjust: exact;
    border: 1px solid black;
  }
}
