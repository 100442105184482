@import "../../styles/colors.scss";


.Map {
  height: 100vh;
  width: 100%;
  // #mapid {
  //   width: 300px;
  //   height: 300px;
  // }



  .leaflet-container {
    width: 100%;
    height: 100vh;

    .leaflet-control-layers-selector {
        height: 1em;
    /* Double-sized Checkboxes */
    -ms-transform: scale(1); /* IE */
    -moz-transform: scale(1); /* FF */
    -webkit-transform: scale(1); /* Safari and Chrome */
    -o-transform: scale(1); /* Opera */
    }

    .leaflet-popup-content-wrapper {
      background-color: $foreground-primary;
      color: $background-secondary;
    }

    .leaflet-popup-tip {
      background-color: $foreground-primary;
    }

    .leaflet-popup-close-button {
      color: $background-secondary;
    }

    .FieldPopup {
      display: grid;
      grid-template: none / 1fr 2fr;
      span:nth-child(2n) {
        font-weight: bold;
      }
    }

    .LayersControl {
      display: flex;
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index: 1000;
    }

    .Loading {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      height: inherit;
      width: inherit;
      z-index: 15000;
    }
  }
  // .leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  //   position: static !important
  // }
}