@import "../../styles/colors.scss";

.FieldSelectList {
  max-height: 50%;

  & > .ReportInfo {
  }

  & > ul {
    max-height: 20em;
    overflow-y: scroll;
    border: 1px solid $foreground-secondary-translucent;
    border-radius: 8px;
    padding: 1em;

   & > li {
     display: flex;


    & > .FieldCardSelect {
     border: 2px solid transparent;
 }     

 
      &.active,
      &:hover {
        & > .FieldCardSelect{
         border: 2px solid $foreground-primary;
        }
      }
    }
  }

}
