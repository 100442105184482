@import "../../styles/colors.scss";

.Modal {
  padding: 2em;
  background-color: $background-secondary;
  color: $foreground-secondary;
  font-family: Young;
  border: 0px;
  border-radius: 15px;

  z-index: 1000;

  input[type="button"] {
    background-color: $foreground-accent;
    color: $background-primary;
    background: $button-gradient;
    height: 2.4em;
    border: 1px solid $foreground-secondary;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1.2em;
    width: 46%;

    &:active {
      background: $button-gradient-active;
      border: 1px solid $foreground-accent;
    }

    &:disabled {
      opacity: 0.25;
    }
  }
}

.ModalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $foreground-secondary-translucent;
  z-index: 1000;
}