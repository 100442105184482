@import "../../styles/colors.scss";

.InputWrapper {
  position: relative;

  input: {
    padding-right: 17px;
  }

  .ShowPasswordIcon {
    color: $background-primary;

    position: absolute;
    right: 3px;
    top: calc(50% - 0.5em);
    font-size: 1em;
  }
}
