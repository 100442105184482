@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";


.PageWithMap {
  height:100vh;
  display: grid;



  & > .FieldsSelector {
      height: calc(100vh - 2em);
      padding: 1em;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      background-color: $background-secondary;
  }

  & > .PageContentWrapper {
       width: 100%;
       height: 100vh;


      & > .PageContent {
        padding: 1em;

        height: calc(100vh - 2em);
        overflow-y: auto;


      }
  }


}

.Map {
  height: 100%;
  width: 100%;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }
}

.PageWithMap.mobile {
  grid-template-columns: 1fr;
  grid-template-rows: 4.5em 1fr; 
}

.PageWithMap.browser {
  grid-template-columns: 4.5em 25em 1fr;
  grid-template-rows: 1fr;
}
