@import "../../styles/colors.scss";

$break-small: 600px;
$break-large: 1200px;

.InfoCenter {
  // font-size: 20px;

  width: inherit;


  .ContentNav {
    display: flex;
    flex-direction: column;
    div:nth-of-type(2n) {
      background-color: $foreground-accent;
    }
  }

  .HeaderHome {
    background-image: url(../../assets/img/sunset-241806.jpg);
    background-size: cover;
    background-position: center right;

    width: 100%;
    height: calc(50vh - 5em);

    .Logo {
      padding: 0.5em 0;
      fill: $background-secondary;
      text-align: right;
      color: $background-secondary;
      height: 8em;
      margin-bottom: 0em;
    }

    .HeaderText {
      @media screen and (min-width: $break-small) {
        position: absolute;
        top: 1em;
        right: 1em;
      }

      margin: 0 auto;

      display: flex;
      flex-direction: column;
      font-size: 20px;
      color: $background-secondary;
      font-weight: bolder;
      width: 17em;

      span {
        font-size: 24px;

        @media screen and (min-width: $break-small) {
          padding-right: 2em;
          text-align: right;
        }
      }
    }


  }

  .PostHeading {
    text-shadow: 5px 2px 20px $background-primary;
    border-bottom: 1px solid $foreground-secondary-translucent;
  }

  .Navigation {
    width: 100%;
    height: 5.7em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: $foreground-secondary;
    background-color: $background-primary;

    a {
      text-align: center;
      height: 4em;
      line-height: 4em;
      cursor: pointer;
      text-decoration: none;
      font-weight: bold;
      color: $foreground-secondary;
      background-color: $background-primary;

      border-bottom: 10px solid $background-primary;

      flex-basis: 0;
      flex-grow: 1;

      span {
        display: inline-block;

        line-height: 1em;
        margin: auto;
        vertical-align: middle;
      }

      &:hover,
      &.active {
        border-bottom: 10px solid $foreground-accent;
        color: $foreground-accent;
        // height: 100%;
      }
    }
  }

  .PanelWrapper {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-gap: 4em;

    @media screen and (max-width: $break-small) {
      grid-template-columns: 1fr;

      .PanelContent {
        width: 90vw;
      }
    }
  }

  .Post {
    padding: 2em;
    background-color: $background-secondary;

    &.Top {
      padding-top: 0;
    }

  }

  .InlineLogo {
    display: inline-block;
    padding: 0.5em 0;
    fill: $foreground-secondary;
    color: $foreground-secondary;
    height: 3em;
    margin-bottom: 0em;
  }

  .ProfilePhoto {
    border-radius: 50%;
  }

  .CarouselImage {
    max-height: calc(100vh * 0.8);
    width: 100%;

    img {
      //height: 100%;

      // width: auto;
    }
  }

}

.VideoIconContainer {
  border: 2px solid $foreground-secondary-translucent;
  color: $foreground-secondary-translucent;

  &:hover {
    border: 2px solid $foreground-accent;

    .VideoIconOverlay {
    color: $foreground-accent;
    }

  }
}
