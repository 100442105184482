@import "../../../styles/colors.scss";

.Notice {
  background-color: $foreground-secondary;
  color: $background-secondary;
  padding: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 1em;
  }
}
