@import "../../styles/colors.scss";


.Farms {
  &> ul {
    max-height: 20em;
    overflow-y: scroll;
    border: 1px solid $foreground-secondary-translucent;
    border-radius: 8px;
    padding: 1em;

    &>li {
      padding: 1em;
      margin-bottom: 1em;
      margin-top: 1em;
      align-items: center;

      background-color: $forest-green;
      color: $foreground-primary;
      border-radius: 8px;
      border: 2px solid transparent;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      word-break: break-all;

     &> span {
        margin-left: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        svg {
          margin-left: 0.25em;
        }
      }

      &.active,
      &:hover {
         border: 2px solid $foreground-primary;
      }
    }
  }

}