@import "../../../styles/colors.scss";


.Header {
  text-transform: capitalize;
  display: flex;
  flex-direction: row;

  svg {
    margin: 1.5em;
    width: 3em;
  }
}
