@import "../../../../styles/colors.scss";

.PageLayoutSimulations {
  display: grid;
  grid-template-columns: 1fr;

  
  .HeatmapLegend {
    margin-bottom: 0;

    .Link {
      color: $background-secondary;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $forest-green;
      }
    }
    div {
      min-height: 1.5em;
    }



    .CompareTitle {
      background-color: $foreground-accent;

      &:hover {
        background-color: $foreground-accent !important;
      }
    }
  }

   

}

   .HybridSummaryTitle {
      height: 5em !important;
      padding-right: 1em;
    }
.HybridSummaryWrapper {
  min-height: 20em;
  max-height: 20em;
  overflow-y: scroll;
  border-bottom: 1px solid $foreground-secondary;
}

.YieldEnv {
  color: $foreground-accent;
  height: 3.5em !important;
}

.HeatmapLegendInput {
  font-size: 0.8em !important;
  line-height: 1em !important;
}

.HybridSummary {
  border-top: 1px solid $foreground-secondary-translucent;
  height: 3em !important;
}
