@import "../../../styles/colors.scss";

.StatusIndicator {
  border-radius: 50%;
  //border: 1px solid $neutral-color;

  display: inline-block;
  height: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
}
