@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.Toolbar {
  display: flex;
  background-color: $background-primary;
  color: $foreground-accent;
  overflow: hidden; /* Added Line*/

  ul {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    // border-right: 1px solid $foreground-secondary-translucent !important;
  }

  li:hover {
    .Icon {
      filter: drop-shadow(0px 0px 5px $foreground-secondary);
    }
  }

  .active {
    background-color: $background-secondary;
    color: $foreground-secondary;
  
    &:hover {
      .Icon {
        filter: none;
      }
    }
  }

  .Tool {
    display: flex;
    justify-content: center;
    align-items: center;


    .Logo {
      fill: $foreground-accent;
      width: 100%;
      height: 4.5rem;
      padding: 0 0.5em;  
      border-right: 1px solid $foreground-accent;
    }

}
  
}

.Toolbar.mobile {
  flex-direction: row;
  grid-column: 1;
  width: 100vw;
  height: 100%;

  ul {
    width: inherit;
    display: flex;
    flex-direction: row;
  }
  .active {
    border-left: 1px solid $foreground-secondary-translucent;
    border-right: 1px solid $foreground-secondary-translucent;
  }

  .Tool {
    width: 4.5rem;
    height: 100%;
  }
}

.Toolbar.browser {
  grid-row: 1;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  ul {
    height: inherit;
    display: flex;
    flex-direction: column;
  }

  .active {
    border-bottom: 1px solid $foreground-secondary-translucent;
    border-top: 1px solid $foreground-secondary-translucent;
    border-left: none;
    border-right: none;
  }

  .Tool {
    width: 100%;
    height: 4.5rem;

    .Logo {
      border-bottom: 1px solid $foreground-accent;
      border-right: none;
    }

    &:last-of-type {
  bottom: 0;
  position: absolute;
}

// &:nth-last-of-type(2) {
//   bottom: 4.5rem;
//   position: absolute;
// }


  }




}

