@import "../../styles/colors.scss";

.FieldCard {
    display:grid;
    grid-template-columns: .5em 1fr;
    //border: 1px solid $foreground-secondary-translucent;
    background-color: $background-primary;
    box-shadow: 1px 1px 4px $foreground-secondary-translucent;
    border-radius: 5px;
    border: 2px solid transparent; 
    margin:  .5em 0;
 

    .FarmColorBlock {
        background-color: green;
        border-radius: 5px 0 0 5px;

    }

    .FieldCardContent {
        display: grid;
        grid-template-columns: 20em 2fr;
        padding: 2px ;


        & > div {
            padding: 2px;
        }

        div:first-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;


           div:first-child {
            font-weight: bold;
            }
            div:last-child {
                font-size: .8em;
            }
        } 

        div:nth-child(2) {
            

            display: flex;
            flex-direction: row;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: space-evenly;
        }
        div:nth-child(3) {
  grid-column-start: 2;
  grid-column-end:3;
}


    }

}
