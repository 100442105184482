@import "./colors.scss";

@font-face {
  font-family: "Young";
  src: url("../assets/fonts/young/Young.ttf") format("truetype");
}

body {
  background-color: $background-primary;
  color: $foreground-primary;
  font-family: "Young";
  width: 100%;
  height: 100vh;
}

.App {
  display: grid;
  display: -ms-grid;

  h1 {
    color: $foreground-secondary;
  }

  ul {
    padding: 0;
  }

  form {
    display: flex;
    flex-direction: column;
    // justify-items: stretch;

    label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    & > label {
      margin-bottom: 1em;
    }
  }

  label {
    line-height: 2.4em;
  }

  input[type="button"],
  input[type="submit"] {
    background-color: $foreground-accent;
    color: $background-primary;
    background: $button-gradient;
    height: 2.4em;
    border: 1px solid $foreground-secondary;
    // border-radius: 8px;
    font-weight: bold;
    font-size: 1.2em;
    word-break: break-all;
    white-space: normal;


    &:active {
      background: $button-gradient-active;
      border: 1px solid $foreground-accent;
    }

    &:disabled {
      opacity: 0.25;
    }
  }

  input {
    background-color: $background-primary;
color: $foreground-primary;

    height: 2.4em;
    border: 1px solid $foreground-secondary;
    border-radius: 8px;
    font-size: 1.2em;

  

  }

  ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $foreground-secondary-translucent;
  opacity: 1; /* Firefox */
}


  textarea {
    background-color: $background-primary;
    color: $foreground-primary;

    border: 1px solid $foreground-secondary;
    border-radius: 8px;
    font-size: 1.2em;

  }



  // input[type="radio"] {
  //   height: 2.4em;
  //   /* Double-sized Checkboxes */
  //   -ms-transform: scale(2); /* IE */
  //   -moz-transform: scale(2); /* FF */
  //   -webkit-transform: scale(2); /* Safari and Chrome */
  //   -o-transform: scale(2); /* Opera */
  // }

  select {
    background-color: $background-secondary;
    color: $foreground-primary;
    border: 1px solid $foreground-secondary;
    border-radius: 8px;
    height: 2.4em;
    width: 12em;
    text-transform: capitalize;
  }
}

.BigText {
  font-size: 24px;
  font-weight: lighter;
}


.AppLoading {
  height: 100vh;
  align-content: center;

  .Loading {
    display: flex;
    position: relative;
    align-items: center;
    font-size: 12em;
    justify-content: center;
    height: inherit;
    width: inherit;
    z-index: 15000;
  }
}


a {
  margin-top: 1em;
  width: 100%;
  text-align: center;
  color: $foreground-secondary;

  &:hover {
    color: $foreground-accent;
  }
}


.ReportInfo {
  display: flex;
  grid-gap: 0.2em;
  grid-template: none/ 1fr 1fr;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 1em;

  span {
    align-content: center;

    &:nth-of-type(2n) {
      height: 2em;
      font-weight: bolder;
    }
  }
}




::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  background-color: $background-primary;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
  background-color: $foreground-secondary;
}



.Tooltip {
  font-family: "Young";
  background-color: $background-primary !important;
  color: $foreground-secondary !important;
}


