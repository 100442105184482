.FarmsPageContent {
    display: grid;
    grid-gap: 1em;
    
}

.circle-picker {
  align-items: center;
  height: 6em;
  padding-bottom: 1em;
  text-align: center;
}

.FarmsPageContent.mobile {
grid-template-columns: 1fr ;

}

.FarmsPageContent.browser {
  grid-template-columns: 1fr 1fr;

}

