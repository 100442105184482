@import "../../styles/colors.scss";


.ReportsWrapper {
    // width: 100%;
    // height: 100vh;


    & > .ReportsContent {
        // height: 100%;
        // overflow-y: auto;


        & > div{
            border: 1px solid $foreground-secondary-translucent;
            border-radius: 5px;
            margin-bottom:1em;
            padding:1em;
            background-color: $background-secondary;

        }


    }

}